<template>
  <div class="assessment p-2">
    <AssessmentPrompt v-if="stage === 'prompt'" @start="startAssessment" />
    <TopicSelection
      v-if="stage === 'topic'"
      :topics="topics"
      @next="selectTopic"
    />
    <SubtopicSelection
      v-if="stage === 'subtopic'"
      :selectedTopic="selectedTopic"
      @next="selectSubtopic"
    />
    <ModeSelection v-if="stage === 'mode'" @next="selectMode" />
    <Assessment
      v-if="stage === 'assessment'"
      :topic="selectedTopic"
      :selectedSubtopic="selectedSubtopic"
      :selectedMode="selectedMode"
    />
  </div>
</template>

<script>
import AssessmentPrompt from './AssessmentPrompt.vue';
import TopicSelection from './TopicSelection.vue';
import SubtopicSelection from './SubtopicSelection.vue';
import ModeSelection from './ModeSelection.vue';
import Assessment from './Assessment.vue';

export default {
  data() {
    return {
      stage: 'prompt',
      topics: [],
      selectedTopic: null,
      selectedSubtopic: null,
      selectedMode: null,
      questions: [],
    };
  },
  methods: {
    async startAssessment() {
      this.stage = 'topic';
    },
    selectTopic(topic) {
      console.log('Selected Topic:', topic);
      this.selectedTopic = topic;
      this.stage = 'subtopic';
    },
    async selectSubtopic(subtopic) {
      this.selectedSubtopic = subtopic;
      this.stage = 'mode';
    },
    selectMode(mode) {
      this.selectedMode = mode;
      this.stage = 'assessment';
    },
  },
  components: {
    AssessmentPrompt,
    TopicSelection,
    SubtopicSelection,
    ModeSelection,
    Assessment,
  },
};
</script>

<style scoped>
</style>
