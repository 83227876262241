<template>
  <div class="card p-3">
    <div v-if="isLoading" class="card__loader"></div>
     <div v-else-if="questions.length === 0 && !loading">
        <h2 class="card__title">{{ selectedSubtopic }} Questionnaire</h2>
      <button @click="generateTest" class="generate-button">Start</button>
    </div>
    <div v-else-if="currentQuestion < questions.length">
    <div class="card__header">
      <h2 class="card__title">{{ selectedSubtopic }} Questionnaire</h2>
    </div>
    <div class="card__content">
    <div class="question">
        <p>{{currentQuestion + 1 + '.  ' + questions[currentQuestion].question_text }}</p>
        <textarea v-model="questions[currentQuestion].user_answer" placeholder="Type your answer here..."></textarea>
      </div>
    </div>
    <div class="card__footer">
      <button
        v-if="currentQuestion < questions.length"
        @click="nextQuestion"
        class="next-button"
      >
        Next
      </button>
    </div>
    </div>
    <div v-else>
      <h5 class="card__title">All questions have been answered.</h5>
      <button @click="submitAnswers" class="submit-button">Submit</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['topic', 'selectedSubtopic', 'selectedMode'],
  data() {
    return {
      currentQuestionIndex: 0,
      topicId: null,
      subtopicId: null,
      questions: [],
      questionnaireId: null,
      currentQuestion: 0,
      questionnaireData: [],
      isLoading: false,
    };
  },
  async mounted() {
    this.fetchIDs();
  },
  computed: {
    questionnaire() {
      return this.$store.state.eddie.questionnaire;
    },
  },
  methods: {
    async fetchIDs() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_AXIOS_URI}/reference-assessment/fetch-assessments`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'Content-Type': 'application/json',
            },
          },
        );
        const data = await response.json();
        const foundTopic = data.find((item) => item.name === this.topic);
        if (foundTopic) {
          this.topicId = foundTopic.id;
          const foundSubtopic = foundTopic.subtopics.find(
            (sub) => sub.name === this.selectedSubtopic,
          );
          if (foundSubtopic) {
            this.subtopicId = foundSubtopic.id;
          }
        }
        console.log(this.topicId, this.subtopicId, this.selectedMode.toLowerCase());
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    },
    async generateTest() {
      this.isLoading = true;
      await this.$store.dispatch('eddie/generateTestOnTheBasisOfSubtopicAndAssessment', {
        subtopicId: this.subtopicId,
        mode: this.selectedMode,
        assessmentId: this.topicId,
      });
      this.questionnaireData = this.$store.state.eddie.questionnaire;
      console.log(this.questionnaireData);
      this.questions = this.questionnaireData.questions;
      // eslint-disable-next-line no-underscore-dangle
      this.questionnaireId = this.questionnaireData.referenced_assessment;
      console.log(this.questionnaireId);
      this.isLoading = false;
    },
    nextQuestion() {
      if (this.questions[this.currentQuestion].user_answer) {
        this.currentQuestion += 1;
      } else {
        // eslint-disable-next-line no-alert
        alert('Please answer the question before proceeding.');
      }
    },
    async submitAnswers() {
      console.log(this.questions);
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_AXIOS_URI}/eddie/update-question-schema`,
          {
            questionnaireId: this.questionnaireId,
            questionnaireData: this.questions,
          },
        );
        console.log(response);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }

      this.currentQuestion = 0;
      this.questionnaireId = null;
      this.questions = [];
    },
  },
};
</script>

<style lang="scss" scoped>

.card {
  position: relative;
  min-height: 100px;

    &__title {
        color : #0d6efd;
        font-weight: 500px;
    }
    &__content {
        font-size: 12px;
        margin-top: 15px;

        .question {
         margin-bottom: 20px;
         p {
            margin-bottom: 20px;
            font-size: 13px;
         }
         textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;
      }
    }
    }
    &__footer {
        text-align: right;
    }
    .next-button, .submit-button, .generate-button {
            background-color: #242424;
            color: white;
            border-radius: 4px;
            padding: 8px;
            font-weight: 500;
            margin-top: 20px;
            border: none;
            overflow: hidden;
        }
        .next-button:hover, .submit-button:hover, .generate-button:hover {
            background-color: white;
            border: 1px solid #242424;
            color: #242424;
        }
        &__loader {
          position: absolute;
          left: 50%;
          border: 3px solid #0d6efd;
          border-top: 3px solid #242424;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
}

</style>
