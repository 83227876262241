<template>
  <div class="card p-3">
    <div class="card__header">
      <h2 class="card__title">Select a Subtopic</h2>
    </div>
    <div class="card__content">
      <div
        v-for="(subtopic, index) in filteredSubtopics"
        :key="index"
        class="radio-group__item"
      >
        <input
          type="radio"
          :id="'subtopic-' + index"
          :value="subtopic.name"
          v-model="selectedSubtopic"
        />
        <label :for="'subtopic-' + index">{{ subtopic.name }}</label>
      </div>
    </div>
    <div class="card__footer">
      <button @click="handleNext" class="button">
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubtopicSelection',
  props: ['selectedTopic'],
  data() {
    return {
      selectedSubtopic: '',
      subtopics: [],
    };
  },
  async mounted() {
    this.fetchTopics();
  },
  computed: {
    filteredSubtopics() {
      return this.subtopics;
    },
  },
  methods: {
    async fetchTopics() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_AXIOS_URI}/reference-assessment/fetch-assessments`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'Content-Type': 'application/json',
            },
          },
        );
        const data = await response.json();
        const filteredTopic = data.find((topic) => topic.name === this.selectedTopic);
        console.log(filteredTopic);
        this.subtopics = filteredTopic.subtopics;
        console.log(this.selectedTopic);
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    },
    handleNext() {
      this.$emit('next', this.selectedSubtopic);
    },
  },
};
</script>

<style lang="scss" scoped>

.card {
    &__title {
        color : #0d6efd;
        font-weight: 500px;
    }
    &__content {
        font-size: 12px;
        margin-top: 15px;
    }
    &__footer {
        .button {
            width: 100%;
            background-color: #242424;
            color: white;
            border-radius: 4px;
            padding: 8px;
            font-weight: 500;
            margin-top: 20px;
            border: none;
        }
        .button:hover {
            background-color: white;
            border: 1px solid #242424;
            color: #242424;
        }
    }
    .radio-group__item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 6px 6px 0px;
    }
}

</style>
