<template>
  <div class="card p-3">
    <div class="card__header">
      <h2 class="card__title">ThoughtJumper Assessment</h2>
    </div>
    <div class="card__content">
      <p>Are you ready to take the assessment?</p>
      <p>This assessment will help us provide personalized learning recommendations.</p>
    </div>
    <div class="card__footer">
      <button @click="$emit('start')" class="button">Start Assessment</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssessmentPrompt',
};
</script>

<style lang="scss" scoped>

.card {
    &__title {
        color : #0d6efd;
        font-weight: 500px;
    }
    &__content {
        font-size: 12px;
        margin-top: 15px;
    }
    &__footer {
        .button {
            width: 100%;
            background-color: #242424;
            color: white;
            border-radius: 4px;
            padding: 8px;
            font-weight: 500;
            margin-top: 20px;
            border: none;
        }
        .button:hover {
            background-color: white;
            border: 1px solid #242424;
            color: #242424;
        }
    }
}

</style>
