<!-- ModeSelection.vue -->
<template>
  <div class="card p-3">
    <div class="card__header">
      <h2 class="card__title">Select Mode</h2>
    </div>
    <div class="card__content">
      <div class="radio-group__item">
        <input
          type="radio"
          id="direct-mode"
          value="Direct"
          v-model="selectedMode"
        />
        <label for="direct-mode">Direct</label>
      </div>
      <div class="radio-group__item">
        <input
          type="radio"
          id="case-study-mode"
          value="Case Study"
          v-model="selectedMode"
        />
        <label for="case-study-mode">Case Study</label>
      </div>
    </div>
    <div class="card__footer">
      <button @click="handleNext" class="button" :disabled="!selectedMode">
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModeSelection',
  data() {
    return {
      selectedMode: '',
    };
  },
  methods: {
    handleNext() {
      this.$emit('next', this.selectedMode);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
    &__title {
        color : #0d6efd;
        font-weight: 500px;
    }
    &__content {
        font-size: 12px;
        margin-top: 15px;
    }
    &__footer {
        .button {
            width: 100%;
            background-color: #242424;
            color: white;
            border-radius: 4px;
            padding: 8px;
            font-weight: 500;
            margin-top: 20px;
            border: none;
        }
        .button:hover {
            background-color: white;
            border: 1px solid #242424;
            color: #242424;
        }
    }
    .radio-group__item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 6px 6px 6px 0px;
    }
}
</style>
